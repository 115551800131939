:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.input {
  box-sizing: border-box;
  margin-bottom: 1rem; }
  .input__content {
    position: relative; }
  .input__field {
    display: block;
    width: 100%;
    border-radius: 0;
    padding: 0.8rem 1rem;
    font-size: 1rem;
    color: #160057;
    border: 1px solid #21125e;
    outline: none;
    box-shadow: 0 0 0 1px #fff;
    background-color: transparent; }
    .input__field:focus, .input__field:not([value=""]) {
      box-shadow: 0 0 0 1px #21125e; }
      .input__field:focus + .input__label, .input__field:not([value=""]) + .input__label {
        background-color: #fff;
        font-size: 0.75rem;
        left: 17px;
        top: 0; }
    .input__field:-webkit-autofill {
      box-shadow: 0 0 0 1px #21125e; }
      .input__field:-webkit-autofill + .input__label {
        font-size: 0.75rem;
        left: 17px;
        top: 0; }
    .input__field--grey:focus + .input__label, .input__field--grey:not([value=""]) + .input__label {
      background-color: #C3EBED; }
    .input__field--grey:-webkit-autofill + .input__label {
      background-color: #C3EBED; }
    .input__field:not([value=""]) + .input__label {
      color: #160057; }
    .input__field:focus + .input__label {
      color: #21125e; }
    .input__field:hover {
      box-shadow: 0 0 0 1px #21125e;
      color: #21125e;
      transition: all 0.3s ease; }
      .input__field:hover + .input__label {
        color: #21125e; }
    .input__field--error {
      border: 1px solid #EE6A39; }
      .input__field--error + .input__label {
        color: #EE6A39; }
      .input__field--error:focus {
        box-shadow: 0 0 0 1px #EE6A39; }
    .input__field--left-icon {
      padding-left: 3rem; }
  .input__label {
    display: inline-block;
    position: absolute;
    color: #7d7d7d;
    top: 50%;
    left: 0.8rem;
    transform: translate(0, -50%);
    padding: 0 6px;
    z-index: 1;
    pointer-events: none;
    transition: all 0.4s ease; }
    .input__label--focus {
      background-color: #fff;
      font-size: 0.75rem;
      left: 17px;
      top: 0; }
  .input__error {
    color: #EE6A39;
    font-size: 0.75rem; }
  .input__help-text {
    color: #7d7d7d;
    font-size: 0.75rem; }
  .input__icon-left {
    position: absolute;
    left: 1rem;
    z-index: 100;
    transform: translate(0, 105%); }
  .input__icon-right {
    position: absolute;
    z-index: 100;
    right: 1rem;
    transform: translate(0, 60%); }
