:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.not-found-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 2rem 1rem; }
  .not-found-page__header {
    font-size: 15rem;
    font-family: "Montserrat";
    font-weight: 900;
    line-height: 100%;
    color: #21125e;
    padding-bottom: 20px; }
    @media (max-width: 540px) {
      .not-found-page__header {
        font-size: 9rem; } }
  .not-found-page__ruler {
    background: #21125e;
    min-width: 10rem;
    height: 2px;
    margin-top: 1rem;
    margin-bottom: 3rem; }
  @media (max-width: 540px) {
    .not-found-page__message p {
      display: inline; } }
  .not-found-page__button {
    margin: 2rem 0;
    min-width: 88%; }
