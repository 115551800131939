:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.login {
  width: 25rem;
  max-width: 100vw; }
  .login__tabs {
    display: flex; }
    .login__tabs span {
      flex-grow: 1;
      width: 100%;
      background-color: #FDF7EF;
      text-transform: uppercase;
      font-weight: 600;
      text-align: center;
      padding: 1rem;
      border-bottom: 1px solid #FDF7EF;
      cursor: pointer; }
      .login__tabs span:first-of-type {
        border-right: 1px solid #fff; }
      .login__tabs span:last-of-type {
        border-left: 1px solid #fff; }
      .login__tabs span.active-tab {
        border-bottom: 1px solid #EE6A39; }
  .login__content {
    padding: 1rem; }
    .login__content .input {
      margin-bottom: 1rem; }
    .login__content__button {
      margin-top: 2rem;
      padding: 0 1.5rem;
      text-align: center; }
    .login__content__password-reminder {
      color: #c4c4c4;
      font-size: 0.875rem;
      margin-top: 2rem; }
