:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.menu-dropdown {
  position: relative; }
  .menu-dropdown__body {
    display: none;
    position: absolute;
    right: 1rem;
    top: 1.5rem;
    box-shadow: 0px 6px 15px 3px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    padding: 1rem;
    width: 15rem; }
    .menu-dropdown__body__rightdown {
      right: 0rem !important;
      left: 1rem !important; }
    .menu-dropdown__body--visible {
      display: block;
      z-index: 2; }
    .menu-dropdown__body ul {
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-weight: normal;
      line-height: 2rem;
      align-items: flex-start; }
      .menu-dropdown__body ul li {
        width: 80%; }
