:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.search {
  width: 100vw; }
  @media (min-width: 992px) {
    .search {
      width: 25rem; } }
  .search--has-results {
    min-height: 100vh; }
  .search .input {
    margin-bottom: 0; }
  .search__input .input__icon-right svg * {
    fill: #EE6A39; }
  .search__input .input__icon-left svg * {
    fill: #160057; }
  .search__input .input__field {
    background-color: #C3EBED;
    border: none;
    box-shadow: none;
    padding-bottom: 1.2rem;
    padding-top: 1.25rem;
    width: 100%; }
    .search__input .input__field:focus {
      box-shadow: none; }
    .search__input .input__field::placeholder {
      text-transform: uppercase;
      font-size: 1rem;
      color: #000; }
  .search__products {
    background: #fff;
    overflow: auto;
    padding: 0.5rem 0;
    position: relative;
    right: 0;
    transition: 300ms;
    height: calc(100vh - 3rem); }
    @media (min-width: 992px) {
      .search__products {
        right: -25rem; } }
    .search__products--expanded {
      right: 0; }
    .search__products--not-found {
      padding: 14px; }
    .search__products__item {
      padding: 0.5rem 1rem; }
      .search__products__item img {
        width: 3.6rem;
        height: 3.6rem;
        display: inline-block;
        margin-right: 1rem;
        vertical-align: top; }
      .search__products__item span {
        display: inline-block;
        width: calc(100% - 4.6rem); }
      .search__products__item h4 {
        text-transform: uppercase;
        font-weight: 600;
        padding: 0.25rem 0;
        color: #160057; }
      .search__products__item p {
        color: #c4c4c4; }
      .search__products__item:hover {
        background-color: #FDF7EF; }
      .search__products__item ul {
        max-height: calc(100vh - 9rem); }
    .search__products__footer {
      position: fixed;
      bottom: 0px;
      border-top: 1px solid #FDF7EF;
      text-align: center;
      padding: 1rem 0;
      background-color: #fff;
      width: 100vw; }
      @media (min-width: 992px) {
        .search__products__footer {
          width: 25rem; } }
