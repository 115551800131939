:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.message {
  width: 25rem;
  padding: 1.5rem;
  background-color: #fff;
  box-shadow: 0px 6px 15px 3px rgba(0, 0, 0, 0.25);
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  border-left: 0.3rem solid #EE6A39; }
  .message--hidden {
    display: none; }
  .message__status-success {
    border-color: #a1eaec; }
  .message__status-error {
    border-color: #EE6A39; }
  .message__title {
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 1.5rem 0 0; }
  .message__content {
    margin: 1rem 0 0; }
  .message__close-icon {
    position: absolute;
    top: 1.8rem;
    right: 1.5rem;
    transition: 0.3s; }
    .message__close-icon svg * {
      transition: 0.3s; }
    .message__close-icon:hover svg * {
      fill: #c4c4c4; }
