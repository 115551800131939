:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.products-featured {
  padding: 2rem 0 4rem;
  text-align: center; }
  .products-featured h3 {
    font-weight: 600;
    margin-bottom: 2rem;
    text-transform: uppercase; }
  .products-featured a {
    color: #160057;
    display: inline-block;
    max-width: 255px;
    text-decoration: none; }
