:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.overlay--modal {
  display: flex;
  overflow-y: auto; }
  @media (min-width: 540px) {
    .overlay--modal {
      align-items: center; } }
  .overlay--modal .overlay__modal {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: inherit;
    width: 100%; }
    @media (min-width: 540px) {
      .overlay--modal .overlay__modal {
        height: auto;
        margin: 0 auto;
        min-height: 455px;
        width: 555px; } }
    .overlay--modal .overlay__modal .modal {
      display: flex;
      flex-direction: column;
      height: inherit;
      min-height: inherit; }
      .overlay--modal .overlay__modal .modal a, .overlay--modal .overlay__modal .modal__action {
        color: #21125e;
        font-weight: bold;
        text-decoration: underline; }
      .overlay--modal .overlay__modal .modal__title, .overlay--modal .overlay__modal .modal__body, .overlay--modal .overlay__modal .modal__footer {
        padding: 1rem; }
      .overlay--modal .overlay__modal .modal__title {
        align-items: center;
        border-bottom: 1px #C3EBED solid;
        color: #160057;
        display: flex;
        font-weight: bold;
        text-transform: uppercase; }
        .overlay--modal .overlay__modal .modal__title p {
          margin-right: auto; }
      .overlay--modal .overlay__modal .modal__body {
        border-bottom: 1px #c4c4c4 solid;
        flex: 1; }
        .overlay--modal .overlay__modal .modal__body__lead {
          color: rgba(22, 0, 87, 0.6);
          font-size: 1.5rem;
          font-weight: 900;
          padding: 1rem 0;
          text-transform: uppercase; }
      .overlay--modal .overlay__modal .modal__footer {
        text-align: right; }
        .overlay--modal .overlay__modal .modal__footer a {
          padding: 1rem; }
      .overlay--modal .overlay__modal .modal__button {
        box-shadow: none;
        margin: 0 1rem;
        padding: 0.1rem 2.7rem;
        width: auto; }
        .overlay--modal .overlay__modal .modal__button span {
          font-size: 1rem; }
      .overlay--modal .overlay__modal .modal__action {
        display: inline-block;
        font-size: 1rem;
        padding: 0 1rem; }
      .overlay--modal .overlay__modal .modal__close div,
      .overlay--modal .overlay__modal .modal__close svg {
        height: 19px;
        width: 19px; }
      .overlay--modal .overlay__modal .modal__close:hover, .overlay--modal .overlay__modal .modal__action:hover {
        cursor: pointer; }
