:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

@media (max-width: 992px) {
  .cart-table td:last-child,
  .cart-table th:last-child {
    text-align: right; } }

.cart-table-row--processing td {
  position: relative; }
  .cart-table-row--processing td::after {
    background-color: rgba(255, 255, 255, 0.65);
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }

.cart-table__cost {
  color: #160057;
  font-weight: 600;
  text-transform: uppercase; }

.cart-table__quantity-header {
  text-align: center; }

.cart-table__quantity-cell {
  text-align: center; }
  .cart-table__quantity-cell div {
    align-items: center;
    display: flex;
    justify-content: space-around; }
    @media (max-width: 540px) {
      .cart-table__quantity-cell div {
        flex-direction: column-reverse; } }
    .cart-table__quantity-cell div.disabled {
      opacity: 0.8; }
      .cart-table__quantity-cell div.disabled:hover svg {
        cursor: default; }
  .cart-table__quantity-cell > div {
    padding: 0.5rem; }

.cart-table svg:hover {
  cursor: pointer; }

.cart-table__thumbnail div {
  display: flex;
  align-items: center; }

.cart-table__thumbnail img {
  width: 50px;
  height: auto; }

.cart-table__checkout-action {
  text-align: right;
  margin: 0 2rem 3rem 0; }
  @media (max-width: 540px) {
    .cart-table__checkout-action {
      text-align: center; } }

.cart-table__empty {
  text-align: center;
  padding: 5rem 0; }
  .cart-table__empty h4 {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 1rem; }
  .cart-table__empty p {
    color: #c4c4c4; }
  .cart-table__empty__action {
    text-align: center;
    margin-top: 1rem; }
