:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

header {
  position: sticky;
  top: 0px;
  z-index: 3; }

.header-with-dropdown {
  z-index: 3; }

.main-menu {
  top: 0;
  align-items: stretch;
  background-color: #FDF7EF;
  border-bottom: 1px solid #160057;
  display: flex;
  flex-direction: row;
  height: 3.55rem;
  position: fixed;
  width: 100vw;
  z-index: 999999; }
  .main-menu__left {
    flex-basis: calc(50% - 2rem); }
    @media (min-width: 540px) {
      .main-menu__left {
        flex-basis: calc(50% - 4rem); } }
    .main-menu__left ul {
      display: flex;
      align-items: center;
      height: 100%; }
  .main-menu__right {
    flex-basis: calc(50% - 2rem); }
    @media (min-width: 540px) {
      .main-menu__right {
        flex-basis: calc(50% - 4rem); } }
    .main-menu__right ul {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
  .main-menu__center {
    align-self: center; }
    .main-menu__center svg {
      width: 4rem; }
      @media (min-width: 540px) {
        .main-menu__center svg {
          width: 6rem; } }
  .main-menu__item {
    font-weight: 600;
    height: 100%;
    text-transform: uppercase; }
    .main-menu__item a {
      text-decoration: none;
      color: #160057;
      position: relative;
      transition: 0.3s; }
      .main-menu__item a:hover {
        color: #EE6A39; }
  .main-menu__hamburger {
    border-right: 1px solid #FDF7EF;
    cursor: pointer;
    padding: 1rem; }
    .main-menu__hamburger--hover {
      display: none; }
    .main-menu__hamburger:hover .main-menu__hamburger--icon {
      display: none; }
    .main-menu__hamburger:hover .main-menu__hamburger--hover {
      display: block; }
    .main-menu__hamburger svg {
      vertical-align: bottom; }
  .main-menu__item, .main-menu__hamburger {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .main-menu__icon {
    padding: 0 1rem;
    cursor: pointer; }
    .main-menu__icon svg * {
      transition: 0.3s; }
    .main-menu__icon:hover svg * {
      fill: #EE6A39; }
    .main-menu__icon svg {
      vertical-align: bottom; }
  .main-menu__cart {
    position: relative; }
    .main-menu__cart__quantity {
      position: absolute;
      top: -0.3rem;
      right: 0.6rem;
      background-color: #21125e;
      border-radius: 1rem;
      padding: 0 0.3rem;
      min-width: 1rem;
      height: 1rem;
      color: #fff;
      font-size: 0.875rem;
      text-align: center;
      line-height: 1rem;
      transition: 0.3s; }
  .main-menu__user--active * {
    fill: #EE6A39;
    fill-rule: inherit; }
  .main-menu__dropdown {
    text-transform: uppercase;
    font-weight: 600; }
    .main-menu__dropdown li {
      cursor: pointer;
      margin: 0 auto; }
      .main-menu__dropdown li:hover {
        color: #c4c4c4; }
      .main-menu__dropdown li:not(:last-of-type) {
        border-bottom: 1px solid #FDF7EF; }
  .main-menu__offline {
    line-height: 24px;
    padding: 1rem; }
    .main-menu__offline span {
      text-transform: uppercase;
      font-weight: 600;
      vertical-align: bottom; }
  .main-menu__search {
    padding: 1rem;
    cursor: pointer;
    line-height: 24px;
    transition: 0.3s; }
    @media (min-width: 992px) {
      .main-menu__search {
        border-left: 1px solid #FDF7EF; } }
    .main-menu__search svg * {
      transition: 0.3s; }
    .main-menu__search:hover {
      color: #EE6A39; }
      .main-menu__search:hover svg * {
        fill: #EE6A39; }
    .main-menu__search span {
      text-transform: uppercase;
      font-weight: 600;
      margin-right: 1rem; }
    .main-menu__search div {
      display: inline-block; }
    .main-menu__search svg {
      vertical-align: bottom; }
  .main-menu__nav-dropdown {
    padding: 0 1.5rem; }
    .main-menu__nav-dropdown--active {
      background-color: #C3EBED; }
    .main-menu__nav-dropdown > li:first-child {
      text-align: center; }
    .main-menu__nav-dropdown__body {
      background: #FDF7EF;
      border-top-color: #FDF7EF;
      border-top-style: solid;
      border-top-width: 1px;
      display: none;
      left: 0;
      padding: 0.5rem;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 3; }
      .main-menu__nav-dropdown__body--visible {
        display: block; }
      .main-menu__nav-dropdown__body li *,
      .main-menu__nav-dropdown__body ul * {
        display: block !important; }
      .main-menu__nav-dropdown__body > ul > li {
        align-self: flex-start;
        margin-left: 2rem;
        position: relative;
        width: 188px; }
        .main-menu__nav-dropdown__body > ul > li > a {
          font-size: 1rem;
          position: relative;
          text-transform: uppercase;
          padding-bottom: 9px;
          margin-bottom: 3px; }
        .main-menu__nav-dropdown__body > ul > li > ul > li:first-child {
          position: relative; }
          .main-menu__nav-dropdown__body > ul > li > ul > li:first-child:before {
            background-color: #C3EBED;
            content: "";
            height: 1px;
            left: 0;
            position: absolute;
            top: -3px;
            width: 100%; }
        .main-menu__nav-dropdown__body > ul > li ul a {
          font-size: 0.875rem;
          font-weight: normal;
          padding: 6px 0;
          text-transform: capitalize; }
        .main-menu__nav-dropdown__body > ul > li ul li > ul {
          margin-left: 10px; }
